body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  font-family: 'Inter', sans-serif;
  user-select: none;
  cursor: default;
}

@keyframes rotating {
  0% {
    transform: translate(0px, 0px) rotate(5deg);
  }
  25% {
    transform: translate(-2px, 0px) rotate(-7deg);
  }
  50% {
    transform: translate(0px, 0px) rotate(9deg);
  }
  75% {
    transform: translate(2px, 0px) rotate(-7deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(5deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
